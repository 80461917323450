interface TrialLinkTrackerOptions {
    selectors: string[];
  }
  
  export default defineNuxtPlugin((nuxtApp) => {
    const options: TrialLinkTrackerOptions = {
      selectors: [
        'a[class*="btn"]',
      ]
    };
  
    if (import.meta.client) {
      nuxtApp.hook('app:mounted', () => {
        const observer = new MutationObserver(() => updateTrialLinks(options.selectors));
        observer.observe(document.body, { childList: true, subtree: true });
        updateTrialLinks(options.selectors);
      });
    }
  });
  
  function updateTrialLinks(selectors: string[]) {
    const currentUrl = new URL(window.location.href);
    const currentParams = currentUrl.searchParams.toString();
  
    selectors.forEach(selector => {
      const links = document.querySelectorAll<HTMLAnchorElement>(selector);
      links.forEach((link) => {
        const linkUrl = new URL(link.href, window.location.origin);
        
        // Preserve existing parameters on the link
        const existingParams = new URLSearchParams(linkUrl.search);
        
        // Add current page parameters, including UTMs
        currentUrl.searchParams.forEach((value, key) => {
          if (!existingParams.has(key)) {
            existingParams.append(key, value);
          }
        });
  
        // Update the link's href
        linkUrl.search = existingParams.toString();
        link.href = linkUrl.toString();
  
      });
    });
  }