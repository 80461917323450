export default defineAppConfig({
  ui: {
    strategy: 'override',
    primary: "purple-heart",
    input: {
      rounded: "rounded-3xl",
      padding: {
        xl: "px-5 py-3",
      },
      size: {
        xl: "text-lg",
      },
      default: {
        size: "xl",
        color: "white",
        variant: "outline",
        loadingIcon: "i-heroicons-arrow-path-20-solid",
      },
    },
    select: {
      rounded: "rounded-3xl",
      padding: {
        xl: "px-5 py-3",
      },
      size: {
        xl: "text-lg",
      },
      default: {
        size: "xl",
        color: "white",
        variant: "outline",
        loadingIcon: "i-heroicons-arrow-path-20-solid",
        trailingIcon: "i-heroicons-chevron-down-20-solid",
      },
    },
    checkbox: {
      label: "text-base font-medium text-gray-700 dark:text-gray-200",
    },
    formGroup: {
      error: 'text-xs mt-2 text-red-500 dark:text-red-400',
      default: {
        size: "xl",
      },
    },
  },
});
