import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93ijD3eR2IQ0Meta } from "/opt/build/repo/pages/careers/open-roles/[...slug].vue?macro=true";
import { default as indexW72zP7cEsDMeta } from "/opt/build/repo/pages/careers/open-roles/index.vue?macro=true";
import { default as _91_46_46_46slug_93zLQ6ieETCtMeta } from "/opt/build/repo/pages/legal/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93k0iowH9k1IMeta } from "/opt/build/repo/pages/qr-code/[...slug].vue?macro=true";
import { default as _91_91tag_93_93Hv5ieJ5CpnMeta } from "/opt/build/repo/pages/resources/[[type]]/tag/[[tag]].vue?macro=true";
import { default as _91_91form_93_93ncd39QUXZLMeta } from "/opt/build/repo/pages/success/[[form]].vue?macro=true";
import { default as indexFDV8Jlqw3fMeta } from "/opt/build/repo/pages/success/index.vue?macro=true";
import { default as component_45stubRU5sOBTecHMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRU5sOBTecH } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93EhWyQvnGPmMeta || {},
    component: () => import("/opt/build/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "careers-open-roles-slug",
    path: "/careers/open-roles/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/careers/open-roles/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "careers-open-roles",
    path: "/careers/open-roles",
    component: () => import("/opt/build/repo/pages/careers/open-roles/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-slug",
    path: "/legal/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/legal/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "qr-code-slug",
    path: "/qr-code/:slug(.*)*",
    meta: _91_46_46_46slug_93k0iowH9k1IMeta || {},
    component: () => import("/opt/build/repo/pages/qr-code/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "resources-type-tag-tag",
    path: "/resources/:type?/tag/:tag?",
    component: () => import("/opt/build/repo/pages/resources/[[type]]/tag/[[tag]].vue").then(m => m.default || m)
  },
  {
    name: "success-form",
    path: "/success/:form?",
    component: () => import("/opt/build/repo/pages/success/[[form]].vue").then(m => m.default || m)
  },
  {
    name: "success",
    path: "/success",
    component: () => import("/opt/build/repo/pages/success/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/posts-sitemap.xml",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stubRU5sOBTecH
  }
]