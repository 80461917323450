export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  if (config.public.NUXT_SITE_ENV === "production") {
    // Add head tags
    useHead({
      meta: [{ name: "google-site-verification", content: "ZkKuU772razJT3sesoM68Fx1CAzirg0oFW1xbJ_VkN4" }],
      script: [
        // GTM Head
        {
          children: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WGJXXPTV');
          `,
        },
      ],
      noscript: [
        {
          children: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WGJXXPTV" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        },
      ],
    });
  }
});
