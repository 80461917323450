let emoji;
let emojiMap = new Map();
let nodeEmojiLoaded = false;
let emojiLibLoaded = false;

async function loadNodeEmoji() {
  if (!nodeEmojiLoaded) {
    emoji = await import("node-emoji");
    nodeEmojiLoaded = true;
    console.log("loadNodeEmoji:Loaded");
  }
}

async function loadEmojiLib() {
  if (!emojiLibLoaded) {
    const emojilib = await import("emojilib");
    for (const [emojiKey, terms] of Object.entries(emojilib.default)) {
      for (const term of terms) {
        emojiMap.set(term, emojiKey);
      }
    }
    emojiLibLoaded = true;
  }
}

const checkEmojis = async () => {
  const emojis = document.querySelectorAll(".emoji-fallback");
  if (emojis.length > 0) {
    await loadNodeEmoji();
    await loadEmojiLib();
  }

  emojis.forEach((emojiEl) => {
    if (!emojiEl.classList?.contains("loaded")) {
      emojiEl.classList.add("loaded");

      let emojiChar = emoji.get(`:${emojiEl.textContent}:`);

      if (!emojiChar) {
        emojiChar = emojiMap.get(emojiEl.textContent);
      }

      if (!/^[a-zA-Z0-9]$/.test(emojiChar)) {
        emojiEl.textContent = emojiChar;
        if (emojiEl.parentNode) emojiEl.outerHTML = emojiEl.innerHTML;
      }
    }
  });
};

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.client) {
    nuxtApp.hook("app:mounted", async () => {
      const observer = new MutationObserver(() => checkEmojis());
      observer.observe(document.body, { childList: true, subtree: true });
      await checkEmojis();
    });
  }
});
